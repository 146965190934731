@import "styles/custom_bootstrap";
@import "~react-notifications-component/dist/scss/notification";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "~react-datepicker/dist/react-datepicker.css";
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import '~react-bootstrap-typeahead/css/Typeahead.bs5.css';

:root {
  --black-60: #{$black-translucent-60};
  --primary-light: #{$primary-light};
}

body {
  min-width: 1200px;
}

.mwc {
  max-width: 1400px;
  @extend .mx-auto;
  @extend .px-3;
}

.color-black-60 {
  color: var(--black-60);
}

.cursor-pointer {
  cursor: pointer;
}

.bg-primary-light {
  background-color: var(--primary-light) !important;
}
