.cartBtn {
  border: 2px solid rgba(18, 18, 18, 0.12);
}

.notificationBtn {
  @extend .cartBtn;

  &.unread {

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: .75em;
      right: .75em;
      width: 10px;
      height: 10px;
      background-color: #25D373;
      border-radius: 100%;
      border: 0;
      margin-left: 0;
    }
  }

  &::after {
    display: none;
  }
}

.userBtn:after {
  display: none;
}

.createBtn {
  @extend .cartBtn;
  @extend .userBtn;
}
