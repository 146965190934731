.cover {
    height: 400px;
    background-position: center center;
    background-size: cover;
    border-radius: 1.5rem;
  
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  
  .priceBox {
    border: 2px solid #90909A;
  }
  
  .artistInfoPhoto {
    width: 500px;
    height: 300px;
    background-position: center center;
    background-size: cover;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // margin: 20px 20px;
    // padding: 2rem 1rem;
  }
  
  .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .backgroundGradient {
    position: relative;
  
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1px;
      height: 1px;
      box-shadow: -20px 0px 160px 200px #e5defb;
      z-index: -1;
    }
  }
  