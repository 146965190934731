
.card {
    max-width: 315px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.imgContainer {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 315px;
    width: 315px;
}

.content {
    padding: 1rem;
}

.title {
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.description {
    color: #4a5568;
}

.tags {
    padding: 1rem 0.5rem;
}

.tag {
    display: inline-block;
    background-color: #edf2f7;
    border-radius: 9999px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #4a5568;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
}

.nftLink {
    max-width: 315px;
    word-wrap: break-word;
}

.cartBtn {
    border: none;
}

.cartBtn:hover {
    background-color: transparent !important;
    border: transparent !important;
}

.cartBtn:focus {
    background-color: transparent !important;
    box-shadow: none !important;
}

.userBtn::after {
    display: none;
}

.createBtn {
    border: none;
    background-color: transparent;
    box-shadow: none;
}

.mainContainer {
    height: 415px;
}
