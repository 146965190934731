@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');


$black-translucent-60: rgba(0, 0, 0, 0.60);

$body-color: rgba(0, 0, 0, 0.87);
$body-bg: #FFFBFF;
$primary: #1935D1;
$primary-light: #DFE0FF;
$border-color: rgba(0, 0, 0, 0.08);
$font-family-base: 'Open Sans', sans-serif;
$input-border-radius: 0.625rem;
$btn-border-radius: 0.625rem;
$input-btn-border-width: 2px;
$input-border-color: $body-color;
$form-check-input-width: 1.5rem;
$form-check-padding-start: 1.5rem;
$form-check-input-border-radius: .5rem;
$form-check-input-border: 2px solid $primary;
$enable-validation-icons: false;
$dropdown-bg: #F6F7FD;
$dropdown-border-color: rgba(0, 0, 0, 0.04);
$dropdown-border-width: 2px;
$dropdown-border-radius: 1rem;
$dropdown-item-padding-y: 1rem;
$dropdown-font-size: .875rem;

$form-floating-label-opacity: 1;
$form-floating-label-transform: scale(.85) translateY(-.95rem) translateX(1rem) !default;
$form-floating-transition: opacity .1s ease-in-out, transform .1s ease-in-out, background-color .1s ease-in-out;
$form-floating-input-padding-t: 1rem;
$form-floating-input-padding-b: 1rem;
$enable-negative-margins: true;

// $form-check-input-checked-color: #{$primary};
$form-check-input-checked-border-color: #{$primary};
$form-check-input-checked-bg-color: #{$primary};
$form-check-input-border: 3px solid rgba($primary, .25);


@import "~bootstrap/scss/mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$spacers: map-merge(
                $spacers,
                (
                        _75x: $spacer * .75,
                        _625x: $spacer * .625,
                        3_75x: $spacer * 3.75,
                        2x: $spacer * 2,
                        5x: $spacer * 5,
                        9x: $spacer * 9,
                )
);

$font-sizes: map-merge(
                $font-sizes,
                (
                        _75x: $font-size-base * .75,
                        2_25x: $font-size-base * 2.25,
                        4x: $font-size-base * 4,
                )
);

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/offcanvas";
@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack


.form-floating {
  > label {
    width: auto;
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-select {
    ~ label {
      background-color: #FFFFFF;
      padding: 0;
      height: auto;
    }
  }

  > textarea.form-control {
    min-height: 6em;
    max-height: 40em;
  }
}

.btn-light {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);

  &:hover {
    background-color: rgba(103, 80, 164, 0.12);
    color: rgba(0, 0, 0, 0.6);
  }
}
