.notificationScroll {
  max-height: 20rem;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 50px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.unread {
  &:after {
    content: '';
    display: block;
    top: .75em;
    right: .75em;
    width: 10px;
    height: 10px;
    background-color: #25D373;
    border-radius: 100%;
    border: 0;
    margin-left: 0;
  }
}